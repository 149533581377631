import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, Tabs, Tab, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { phaseStyles } from './styles';
import { themeStyles } from '../../styles';
import clsx from 'clsx';
import ThemeContext from '../../utils/theme-context';

 
  const PopupDialogTitle = withStyles()((props) => {
    const classes = phaseStyles();
    const {
      children, onClose, ...other
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


export default function ContentWithPhasePreviews({ data }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState("Phase 1:");
    const classes = phaseStyles(data);
    let firstTab = data.tabPhases[0];
    const cls= clsx(classes.phaseIcon, classes.preview, classes.disabled);
    const partnerTheme = useContext(ThemeContext);
    const globalthemClasses = themeStyles(partnerTheme);
    const renderOption = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    };
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };    
    const truncate = (input) => {
        let inputContent= input[0]?.props.children[0][1];        
        inputContent = inputContent.substring(0, 120)+"...";
        return inputContent;
    }   

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container className={classes.phaseContainer}>
            <Grid container spacing={1}>                
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Typography variant="h3" className={classes._h1}>{data.title}</Typography>
                    <div className={classes.underline}>
                        <hr className={clsx(globalthemClasses.progressBarColor, classes.hr)} />
                    </div>
                    {data?.subText?.raw && (
                    <Typography variant="body2" className={classes.phasePreviewContent}>
                       {documentToReactComponents(JSON.parse(data.subText.raw), renderOption)}     
                    </Typography>
                    )}  
                    <Button variant="contained" className={classes.phaseButton} onClick={() => handleClickOpen()}>See All Phases</Button>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div className={classes.cmpPhasePreview}>
                       <div className={classes.promoWrapper}>
                          <div className={classes.cmpPhasesSteps}>  
                                 {data.tabPhases.map((user, index) => (
                                    <>
                                    <div className={clsx(index == 0 ? classes.underToneColor : '', classes.phaseIcon)}>
                                      {index == 0 ?  <div className={clsx(classes.underToneColor, classes.triangle)}></div>: ""}
                                        <div className="cmp-icon-svg icon">
                                            <img src={user.icon.file.url} className={classes.imgFile}/>
                                        </div>
                                    </div>
                                    <div className={classes.line}></div>      
                                    </>                            
                                 ))}
                          </div>   
                            <div className="promo-content">
                                <p className={classes.title}>
                                    <span className="phase" style={{color: "#6400FF"}}>1.</span>
                                    <span className={classes.titleText}>{firstTab.phaseTitle}</span>
                                </p>
                                {firstTab?.phaseContent?.raw && (
                                    <Typography variant="body2" className={classes.phaseDescription}>
                                       {truncate(documentToReactComponents(JSON.parse(firstTab.phaseContent.raw), renderOption))}
                                    </Typography>
                                )}    

                            </div>
                            <Button variant="text" className={classes.promoLink} onClick={() => handleClickOpen()}>See All Phases</Button>                
                       </div> 
                    </div>
                </Grid>
            </Grid>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}     
                maxWidth="md"          
            >
                <PopupDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Modal title
                </PopupDialogTitle>
                    <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={value} scrollButtons="auto">
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className={classes.tabHeight}>
                                        {data?.tabPhases.map((tab, indexloop) => (
                                            <Tab icon={ <img src={tab.icon.file.url} className={classes.tabimgFile}/>} label={<span className={classes.spanTabLabel}>{tab.shortLabel}</span>} value={tab.shortLabel}/>
                                        ))}
                                        </Tabs>
                                </Box>
                                {data?.tabPhases.map((tabs, indexnum) => (
                                            <TabPanel value={tabs.shortLabel} index={indexnum}>
                                                <Typography variant="h3" className={classes.phaseTabTitle}>{tabs.shortLabel} {tabs.phaseTitle}</Typography>
                                                <Typography variant="body2">
                                                    {documentToReactComponents(JSON.parse(tabs.phaseContent.raw), renderOption)}
                                                </Typography>
                                            </TabPanel>
                                ))}       
                                </TabContext>                 
                        </Box>
                    </DialogContent>                    
                </Dialog>
        </Container>
    )
}