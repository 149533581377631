import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const phaseStyles = makeStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    phaseContainer: {
        padding: '30px 0px !important'
    },
    _h1:{
        fontSize:'2.5em !important',
        fontWeight: 'bold',
        lineHeight: '1.3'
    },
    phasePreviewContent:{
        fontWeight: 'lighter',
        padding: '30px 0',
        lineHeight:'1.5'
    },
    phaseButton :{
      padding: '15px 30px',
      width: '65%',
      textAlign: 'center',
      cursor: 'pointer',
      textTransform: 'none !important',
    },
    phaseTabTitle :{
      fontWeight:'bold',
    },
    hr: () => ({
        width: '80px',
        height: '8px',
        border: 'none',
        borderRadius: '0',
        [theme.breakpoints.up('xs')]: {
          margin: '0 auto'
        },
        [theme.breakpoints.up('md')]: {
          margin: '0'
        },
        [theme.breakpoints.up('lg')]: {
          margin: '0'
        }
      }),
      underline: () => ({
        width: '100%',
      }),
      promoWrapper : {
        margin: '0 30px',
        marginRight: '0',
        paddingLeft: '20px',
        padding: '10px 0 20px 0',
        boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)'
      },     
      cmpPhasesSteps:{
          whiteSpace: 'nowrap',
          overflow: 'hidden'
      },
      titleText:{
        fontWeight:'bold'
      },
      title:{
        borderBottom: '1px solid #000',
        paddingBottom: '10px',
        fontWeight:'bold'
      },
      promoLink:{
        padding: '20px',
        margintop:'20px',
        display: 'inline-block',
        color: '#0567BA !important'
      },
      phaseIcon :{
        cursor: 'pointer',
        width: '105px',
        height: '90px',
        display: 'inline-block',
        borderRadius: '50%',
        position: 'relative',
        padding: '10px',
        margin: '20px 10px',
        backgroundColor: '#efefef',        
      },
      imgFile:{
        width: '40px !important',
        height: '40px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'all 500ms ease-in-out'
      },
      spanTabLabel:{
          marginTop:'80px',
      },
      tabimgFile:{
        width: '100px !important',
        height: '45px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'all 500ms ease-in-out'
      },
      tabHeight:{
        minHeight:'70px !important'
      },
      line:() => ({
        display: 'inline-block',
        width: '40px',
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'relative',
          width: '100%',
          top: '50%',
          borderTop: '4px dotted #efefef',
          display: 'inline-block',
          height: '60px'
        }
      }),
      underToneColor:{
        backgroundColor:'#6400ff45'
      },
      triangle : {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderTop: '20px solid #6400ff45',
        bottom: '-17px',
        transform: 'translateX(-50%)',
        left: '50%',
        backgroundColor: '#fff'
      },
      closeButton: {
        position: 'absolute',
        textAlign: 'right',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
      }
}));