import React, { useState, useContext } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import Person from "../card-person";

export default function PeopleContactInfo({ data }) {
    return(
        <Container>
            <Grid >
                { data?.primaryContact ? 
                    <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Person data={"0"} person={data?.primaryContact} />
                    </Grid>
                : "" }                
                <Grid container spacing={1}> 
                    {
                        data?.otherContacts?.map((contact, index) => <Person data={index} person={contact} />)
                    }
                </Grid>
            </Grid>
        </Container>   
    )
}