import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import phoneImg from '../../images/icon-phone.svg';
import cellImg from '../../images/icon-mobile.svg';
import mailImg from '../../images/icon-mail.svg';
import addrImg from '../../images/icon-addr.svg';

const useStyles = makeStyles((theme) => ({
    root: {
      /*maxWidth: 345,*/
      margin:'10px 30px',
    },
    media: {
      height: '200px',
      borderRadius: '50%',      
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    cardContent:{
      textAlign:'center',
    },
    cardPerson:{
      fontWeight:'bold',
    },
    list:{
      listStyle:'none',
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
    },
    sectionContact:{
      display:'flex',
      flexDirection: 'row',
      paddingLeft: '100px',
      paddingTop:'10px',
    },
    space:{
      paddingLeft:'10px',
      textAlign: 'left'
    },
    iconWrapper:{
      width:'23px',
      height:'23px'
    }
  }));
    
  export default function CardPerson({ i, person }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(-1);

    const handleExpandClick = () => {
        setExpanded(expanded=== i ? -1 : i);
    };
    return(
        <Grid item lg={6} md={12} sm={12} xs={12}>          
          <Card elevation={0}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <img className={classes.media} src={person?.photo?.file?.url}/>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                  <CardContent className={classes.cardContent}>
                    <Typography variant='h2' className={classes.cardPerson}>
                    {person?.fullName}
                    </Typography>
                    <Typography variant='body1'>
                    {person?.role}
                    </Typography >
                    <Typography variant='body2'>
                    {person?.department}
                    </Typography>                      
                  </CardContent>
                  <CardActions disableSpacing>
                      <Typography>
                          Contact Info
                      </Typography>
                      <IconButton    
                      className={expanded=== i  ? classes.expand : classes.expandOpen}                               
                      onClick={handleExpandClick}
                      aria-expanded={expanded === i}
                      aria-label="Contact Info"
                      >
                      <ExpandMoreIcon />
                      </IconButton>
                  </CardActions>
                </Grid>
              <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                      <ul className={classes.list}>
                          {person?.officePhone && <li className={classes.sectionContact}>
                            <div className={classes.iconWrapper}>
                                <img src={phoneImg} alt="Office Phone" />
                            </div>
                            <span className={classes.space}>Office: {person?.officePhone}</span>
                        </li>}
                        {person?.cellPhone && <li className={classes.sectionContact}>
                            <div className={classes.iconWrapper}>
                                <img src={cellImg} alt="Mobile Phone" />
                            </div>
                            <span className={classes.space}>Cell: {person?.cellPhone}</span>
                        </li>}
                        {person?.email && <li className={classes.sectionContact}>
                            <div className={classes.iconWrapper}>
                                <img src={mailImg} alt="Email" />
                            </div>
                            <a className={classes.space} href={`mailto:${person?.email}`} tabIndex="0">{person?.email}</a>
                        </li>}
                        {person?.address && <li className={classes.sectionContact}>
                              <div className={classes.iconWrapper}>
                                  <img src={addrImg} alt="Address" />
                              </div>
                              <div>
                                <div className={classes.space}>
                                  {person.address1 ?<span>{person.address1}<br/></span>:''}
                                  {person.address2 ? <span>{person.address2}<br/></span>:''}
                                  {person.address3 ? <span>{person.address3}<br/></span>:''}
                                  {person.cityStatePostalCode ?<span>{person.cityStatePostalCode}<br/></span>:''}
                                  {person.country ?<span>{person.country}<br/></span>:''}
                                </div>    
                              </div>                        
                        </li>}
                      </ul>                    
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    )
  };
