/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Container, Typography } from '@material-ui/core';
import { richTextStyles } from './styles';
import ThemeContext from '../../utils/theme-context';

export const RichText = ({ data }) => {
  const partnerTheme = useContext(ThemeContext);
  const classes = richTextStyles(partnerTheme);
  const richtextOptions = {
    renderMark: {
      [MARKS.CODE]: code =>{
         let codeRender =  code[0]=false ? "" : code[1];
         return <div dangerouslySetInnerHTML={ {__html:  codeRender }} />     
      }
    },
    renderNode: {    
      [BLOCKS.EMBEDDED_ASSET] : (node, children) => {
          let url;
          let title;
          data?.richText?.references?.filter(reference => reference.contentful_id == node.data.target.sys.id)
          .map((reference)=>(
            title = reference?.title,
            url = reference?.file?.url
          ))
          return(
            <>
            <img src={ url!=undefined ? url : "#" } alt={title} />
            </>
          )
      },
      [BLOCKS.EMBEDDED_ENTRY] : (node, children) => {
        console.log('Embedded Entry:',node);
      },
      [INLINES.EMBEDDED_ENTRY] : (node, children) => {
        console.log("Inline Embedded Entry",node);
      },
      [INLINES.ENTRY_HYPERLINK] : (node, children) => {
        let url;
        let title;
        data?.richText?.references?.filter(reference => reference.contentful_id == node.data.target.sys.id)
        .map((reference)=>(
          title = reference?.pageName,
          url = reference?.slug
        ))
        return <a href={ url!=undefined ? url : '#' }>{children}</a>
      },
      [INLINES.ASSET_HYPERLINK] : (node, children) => {
        let url;
        let title;
        data?.richText?.references?.filter(reference => reference.contentful_id == node.data.target.sys.id)
        .map((reference)=>(
          title = reference?.title,
          url = reference?.file?.url
        ))
        return <a href={ url!=undefined ? url : '#' }>{children}</a>
      },
   },
   renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return (
    <Container>
      {data?.richText?.raw && (
        <Typography variant="body2" className={classes.richTextPara}>
          {documentToReactComponents(JSON.parse(data?.richText?.raw), richtextOptions)}
        </Typography>
      )}      
    </Container>
  );
};
